<template>
<Reg-layouts>
   <div class="main__content">
       <div :class="{ 'popup-visible' : this.user.restore_success }">
            <div class="popup-wrap">
                <div class="popup successfully-popup" :class="{ 'active' : this.user.restore_success }">
                    <form action="#" class="popup__form">
                        <div class="popup__header">
                            <div class="popup__title">
                                <span class="title-text"></span>
                            </div>
                            <div class="popup-remove" @click="popupRemove">
                                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
                                        fill="#333333"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                        fill="#333333"/>
                                </svg>
                            </div>
                        </div><!-- //popup__header -->
                        <div class="popup__content">
                            <div class="popup__section">
                                <div class="wysiwyg">
                                    <p>
                                        Password sucessfully updated.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="popup__footer">
                            <div class="btn-wrap btn-wrap_secondary content-right single-btn-right">
                                <div class="form-field"><div class="form-field__content"><button @click.prevent="popupRemove" class="btn btn_primary">Ok</button></div></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        <form class="main__form" v-on:submit.prevent="resetPassword">
            <div class="section section_mobile-secondary">
                
                <div class="section__content">
                    <div class="account">
                    <div class="account__steps steps">





                        <div class="steps__item step">
                            <div class="step__header">
                                <!-- <div class="step__number">
                                    <span class="number-text">1</span>
                                </div> -->
                                <div class="step__title">
                                    <span class="title-text">Please set up a new password</span>
                                </div>
                            </div>
                            <div class="step__content">

                                <div class="row">
                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Password *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input 
                                                v-model="password" v-on:input="passwordHandler"
                                                class="form-field__input" type="password"
                                                        placeholder="Please enter password" :required="true">
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>

                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Re-write Password
                                                    *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input class="form-field__input" type="password"
                                                        placeholder="Please enter password" 
                                                        v-model="repassword" :required="true">
                                            </div>
                                        </div><!-- //form-field -->

                                    </div>

                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header content-between">
                                                <span class="form-field__title">Password strength:</span>
                                                <span class="form-field__progress" :class="passStrengthText">{{this.passStrengthValue}}</span>
                                            </div>
                                            <div class="form-field__content">
                                                <div class="progressbar">
                                                    <div :class="passStrengthClass">

                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- //form-field -->

                                    </div>

                                    <div class="reset-password-notify-popup"
                                      :class="{ 'active' : this.regPassErrNotify }">
                                      {{ regPassErrNotify }}
                                  </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    </div>
                </div>

                <div class="section__footer">
                  
                    <div class="btn-group content-between">
                        <router-link :to="{name: 'cover'}" class="btn btn_secondary">Cancel</router-link>
                        <div class="form-field__content">
                            <button type="submit" class="btn btn_primary"><span
                                    class="btn__text">Reset Password</span>
                            </button>
                        </div>
                        <!-- <button type="submit" @click="signup" class="btn btn_primary">Sign Up</button> -->
                    </div>
                </div>

            </div>
        </form>
    </div>
        <!-- {{ info }} -->
</Reg-layouts>
</template>

<script>
    import store from '../store'
    import RegLayouts from "../layouts/Reg.vue"
    import { router } from '../router'
    import { passwordStrength } from 'check-password-strength'
    
    const passCustomOptions = [
      { 
        id: 0,
        value: "Too weak",
        minDiversity: 0,
        minLength: 0 
      },{  
        id: 1,
        value: "Weak",
        minDiversity: 2,
        minLength: 4 
      },{
        id: 2,
        value: "Medium",
        minDiversity: 3,
        minLength: 6
      },{
        id: 3,
        value: "Strong",
        minDiversity: 3,
        minLength: 8
    }];

    export default {
        name: "ResetPassword",
        components: {
            RegLayouts,
        },
        data() {
            return {
                password: '',
                repassword: '',
                passStrengthValue: '',
                passStrengthId: '',
                passStrengthClass: 'progressbar__0',
                passStrengthText: 'strength-text__0',
                regPassErrNotify: '',
            }
        },
        methods: {
            passwordHandler: function() {
                if(this.password.length <=0){
                    this.passStrengthValue = '';
                    this.passStrengthId = '';
                    this.passStrengthClass = 'progressbar__0';
                    this.passStrengthText = 'strength-text__0';
                } else {
                    this.passStrengthValue = passwordStrength(this.password, passCustomOptions).value;
                    this.passStrengthId = passwordStrength(this.password, passCustomOptions).id;

                    switch (this.passStrengthId) {
                        case 0:
                            this.passStrengthClass = 'progressbar__25';
                            this.passStrengthText = 'strength-text__25';
                            break;
                        case 1:
                            this.passStrengthClass = 'progressbar__50';
                            this.passStrengthText = 'strength-text__50';
                            break;
                        case 2:
                            this.passStrengthClass = 'progressbar__75';
                            this.passStrengthText = 'strength-text__75';
                            break;
                        case 3:
                            this.passStrengthClass = 'progressbar__100';
                            this.passStrengthText = 'strength-text__100';
                            break;
                    }
                }
            },
            resetPassword: function() {
                this.regPassErrNotify = '';
                var data = {};
                data.password = this.password;
                // data.resetkey = router.currentRoute._value.params.key;
                data.token = router.currentRoute._value.params.key
                data.email = router.currentRoute._value.params.email

                if(this.password !== this.repassword) {
                  this.regPassErrNotify = 'Password and Re-Password do not match';
                  setTimeout(() => {
                    this.regPassErrNotify = '';
                  }, 3000);
                  return;
                }
                if(passwordStrength(this.password, passCustomOptions).id < 3) {
                  this.regPassErrNotify = 'Capitalised letters and numbers are required';
                  setTimeout(() => {
                    this.regPassErrNotify = '';
                  }, 3000);
                  return;
                }

                store.dispatch('RESET_PASSWORD', data);
            },
            popupRemove: function() {
              router.push({name: "cover"}); 
            }
        },
        computed: {
            company: () => store.state.company,
            user: () => store.state.user
        }
    }
</script>
